<template>
  <div class="row">
    <div class="col-10">
      <div v-if="project.description" style="font-size: 12px">
        <p class="mb-0 pl-3">
          {{ project.description }}
        </p>
      </div>
      <div v-else>No Project Story available for this Project</div>
    </div>
    <div class="col-2">
      <div class="text-right mb-2" style="margin-top: -18px">
        <MawjBtn label="Edit" @click="openStoryModal = true" />
        <story-modal @reload="loadInfo" :project="project" :open="openStoryModal" @hide="openStoryModal = false;" />
      </div>
    </div>



  </div>
</template>

<script> 
import MawjBtn from "@/components/common/mawjBtn.vue";
import storyModal from "../mainTab/storyModal.vue";
export default {
  props: ["project"],
  components: {
    MawjBtn,
    storyModal,
  },
  created() {
    this.loadInfo();
  },
  data() {
    return {
      storyInfo: null,
      loading: false,
      openStoryModal: false,
      selectedstory: null,
      
    };
  },
  methods: {
    loadInfo() {

    },

  },
};
</script>
