<template>
  <div class="mawj-custom-tab user-main-tab-st mt-6">
    <b-tabs content-class="mt-3" pills card>
      <b-tab title="Specification" active>
        <timelineTab :project="data" />
      </b-tab>
      <b-tab title="Location">
        <aboutMainTab @reload="$emit('reload')" :data="data" />
      </b-tab>
      <b-tab title="Images">
        <image-list @reload="$emit('reload')" :project="data" />
      </b-tab>
      <b-tab title="Certificates">
        <awardTab :userId="data._id" />
      </b-tab>
      <b-tab title="Project Story">
        <project-story :project="data" />
      </b-tab>
      <b-tab title="Stamps">
        <certificateTab :userId="data._id" />
      </b-tab>
      <b-tab title="Claims">
        <certificateTab :userId="data._id" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { userMethods } from "@/state/helpers";
import aboutMainTab from "./mainTab/about.vue";
import ImageList from "./mainTab/image-list.vue";
import awardTab from "./mainTab/award.vue";
import ProjectStory from "./mainTab/project-story.vue";
import certificateTab from "./mainTab/certificates.vue";
import timelineTab from "./mainTab/timeline.vue";
export default {
  props: ["data"],
  components: {
    aboutMainTab,
    ImageList,
    awardTab,
    certificateTab,
    timelineTab,
    ProjectStory
  },
  data() {
    return {
      userData: null,
    };
  },
  created() {
    this.getUser().then((res) => {
      this.userData = res;
    });
  },
  methods: {
    ...userMethods,
  },
};
</script>

<style>
.user-main-tab-st .card-header {
  padding: 10px;
}

.user-main-tab-st .tab-pane {
  padding: 0px;
}
</style>
