<template>
  <div class="row" v-if="!edit">
    <div class="col-lg-2 col-md-3 col-2 prj-image-card m-2" v-for="img, index in project.images " :key="index">

      <div class="p-2 d-flex justify-content-center">
        {{ (img.type || '').replace("_", " ").toUpperCase() }}
      </div>
      <img class="prj-img-top" :src="img.url" alt="Card image cap">
      <div class="p-2 d-flex justify-content-center">
        {{ img.description }}
      </div>
    </div>

  </div>
</template>

<script>
import MawjBtn from "@/components/common/mawjBtn.vue";
import { users } from "@/config/api/users.js";
import Swal from "sweetalert2";
export default {
  props: ["project"],
  components: {
    MawjBtn,
  },
  watch: {
    userData(val) {
      this.formValues = {
        linkedin_url: val.linkedin_url,
        instagram_url: val.instagram_url,
        facebook_url: val.facebook_url,
        twitter_url: val.twitter_url,
        youtube_url: val.youtube_url,
        behance_url: val.behance_url,
      };
      this.selectedSocialMedia = val.active_links.map((item) => {
        return item.social_media;
      });
    },
  },
  data() {
    return {
      formValues: {
        linkedin_url: null,
        instagram_url: null,
        facebook_url: null,
        twitter_url: null,
        youtube_url: null,
        behance_url: null,
      },
      edit: false,
      selectedSocialMedia: [],
      updatingLink: false,
      updatingDisplayLink: false,
    };
  },
  methods: {
    buttonClicked() {
      this.edit = true;
    },
    updateSocialLink() {
      const api = users.changeActiveSocialLinks;
      api.id = this.userData._id;
      this.updatingDisplayLink = true;
      api.data = {
        active_social_medias: this.selectedSocialMedia,
      };
      this.generateAPI(api)
        .then((res) => {
          this.$emit("reload");
          Swal.fire({
            title: "Social Media link display updated.",
          });
        })
        .catch((err) => {
          Swal.fire(
            "Social Media link display updated!",
            `${err.response.message}`,
            "error"
          );
        })
        .finally(() => {
          this.updatingDisplayLink = false;
        });
    },
    updateSocialMediaLink() {
      const api = users.update;
      api.id = this.userData._id;
      this.updatingLink = true;
      api.data = this.formValues;
      this.generateAPI(api)
        .then((res) => {
          this.$emit("reload");
          Swal.fire({
            title: "Social Media link updated successfully.",
          });
          this.edit = false;
        })
        .catch((err) => {
          Swal.fire({
            title: "Social Media link is not updated!",
            icon: "error",
          });
        })
        .finally(() => {
          this.updatingLink = false;
        });
    },
  },
};
</script>
<style>
.prj-image-card {
  border-radius: 10px;
  background: #576E85;
  color: #fff;
  padding: 0px;
  font-size: 10px;
}

.prj-img-top {
  width: 100%;
  height: 126px;
  object-fit: cover;
}

.img-card {
  padding: auto;
  background-color: #000;
  min-height: 200px;
}



.social-link-edit .formulate-input[data-classification="button"] button {
  padding: 6px;
  min-width: 48px !important;
}

.social-link-edit .formulate-input[data-classification="text"] input {
  width: 280px;
}

.social-media-active-indicator {
  width: 18px;
  background-color: #fff;
  height: 14px;
  border-radius: 12px;
  border: 1px solid #eceeee;
  display: inline-block;
  margin: 2px;
  margin-right: 4px;
}

.social-media-active-indicator.active {
  background-color: rgb(88, 110, 132);
}
</style>
