<template>
  <div class="user-expert-container mb-5">
    <BlockHeader title="General" />

    <div class="d-flex justify-content-between mb-3">
      <strong> Creator </strong>
      <span> {{ (data.createdBy && data.createdBy.name) || "Not Provided" }} </span>
    </div>
    <div class="d-flex justify-content-between mb-3">
      <strong> Created On</strong>
      <span> {{ (data.createdAt && moment(data.createdAt).format('ll')) || "Not Provided" }} </span>
    </div>


  </div>
</template>

<script>
import BlockHeader from "./blockHeader.vue";
import FileUploader from "./fileUploader.vue";
import { users } from "@/config/api/users";

export default {
  components: {
    BlockHeader,
    FileUploader,
  },
  props: ["data"],
  data() {
    return {
      users,
    };
  },
  computed: {
    profession() {
      if (this.data) {
        return this.data.sub_profession ? this.data.sub_profession.name : "";
      }

      return "";
    },
    becomeExpert() {
      if (this.data) {
        return this.data.sub_profession
          ? this.moment(this.data.sub_profession.createdAt).format("LL")
          : "";
      }

      return "N/A";
    },
    expertDocuments() {
      return (
        (this.data &&
          this.data.expert_documents &&
          this.data.expert_documents.profession_document) ||
        null
      );
    },
  },
};
</script>

<style>
.user-expert-container {
  font-size: 12px;
  width: 184px;
}
</style>
