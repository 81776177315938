<template>
  <div>
    <imageUploaderModal
      :open="open"
      :data="{ id, url }"
      v-on:closed="() => (open = false)"
      @updated="$emit('updated')"
    />
    <div style="text-align: end">
      <p class="pointer" @click="openModal">Edit</p>
    </div>

    <div class="profile-image">
      <img :src="url" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
import imageUploaderModal from "@/components/common/imageUploaderModal.vue";

export default {
  props: ["url", "id"],
  components: {
    imageUploaderModal,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openModal() {
      this.open = true;
    },
  },
};
</script>

<style scoped>
.profile-image {
  border: 1px solid #838383;
  text-align: center;
  padding: 2px;
  margin-bottom: 18px;
  width: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
}
.profile-image img {
  width: 100%;
  max-height: 180px;
}
</style>

<style>
.img-cropper div {
  text-align: center;
  padding-bottom: 28px;
}
</style>
