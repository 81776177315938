<template>
  <b-modal id="story-modal" hide-footer @hidden="$emit('hide')" :title="isUpdate ? 'Update story' : 'Create story'">
    <div class="p-2">
      <FormulateForm :values="project" @submit="submitStoryData">


        <FormulateInput type="textarea" name="description" label="Description" />


        <div class="text-right my-2 mb-3">
          <FormulateInput type="submit" :label="sending?'Updating...':'Update story'" />
        </div>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { projects } from "@/config/api/projects";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["open", "project",],
  watch: {
    open(val) {
      if (val) {
        this.$bvModal.show("story-modal");
      }
    },

  },
  data() {
    return {
      formValues: {},
      sending: false,
      isUpdate: false,
    };
  },

  methods: {
    submitStoryData(val) {
      this.updateStory(val)
    },

    updateStory(val) {
      const api = projects.model.update;

      api.data = {
        project_id: this.project.project_id,
        _id: this.project._id,
        description: val.description


      };

      this.sending = true;
      this.generateMultipartAPI(api)
        .then(() => {
          this.project.description = val.description
          Swal.fire("Story", "Story updated successfully.", "success");
          this.$bvModal.hide("story-modal");
          this.$emit("reload");
        })
        .catch((err) => {
          Swal.fire(
            "Story",
            `Story is not updated, ${err.response.data.message}!`,
            "error"
          );
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>

