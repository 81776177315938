<template>
  <div>
    <div v-if="project" style="max-height:680px;overflow-y:auto">
      <table>
        <tr>
          <td class="pr-5">
            Building Type
          </td>
          <td>
            {{ project.sub_project_type && project.sub_project_type.name }}
          </td>
        </tr>
        <tr>
          <td class="pr-5">
            Year Start
          </td>
          <td>
            {{ dateToYear(project && project.end_date) }}
          </td>
        </tr>
        <tr>
          <td class="pr-5">
            Year End
          </td>
          <td>
            {{ dateToYear(project && project.start_date) }}
          </td>
        </tr>
        <tr>
          <td class="pr-5">
            Area
          </td>
          <td>
            {{ project.sub_project_type && project.sub_project_type.name }}
          </td>
        </tr>
        <tr>
          <td class="pr-5">
            Floors
          </td>
          <td>
            {{ project.sub_project_type && project.sub_project_type.name }}
          </td>
        </tr>
        <tr>
          <td class="pr-5">
            Cost
          </td>
          <td>
            {{ costCalc }}
          </td>
        </tr>
      </table>
    </div>

    <div v-if="project.custom_specifications" style="max-height:680px;overflow-y:auto">
      <span class="title">Custom Specifications</span>
      <table v-if="project.custom_specifications">
        <tr v-for="item, key in project.custom_specifications" :key="item.index">
          <td class="pr-5">
            {{ key }}
          </td>
          <td>
            {{ item }}
          </td>
        </tr>
      </table>
      <div v-else>No Time line Data available</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      timeline: null,
      loading: false,
    };
  },
  computed: {

    costCalc() {
      if (this.project && this.project.project_currency && this.project.project_cost) {
        return Intl.NumberFormat('en-IN', { style: 'currency', currency: this.project.project_currency, maximumFractionDigits: 0 }).format(this.project.project_cost)
      }
      return ""
    },
  },
  methods: {
    dateToYear(date) {
      if (!date)
        return "N/A";
      return this.moment(date).format("YYYY")
    },
  },
};
</script>
<style>
.title {
  color: #000;
}
</style>
