<template>
  <div class="user-profile">
    <div class="d-flex justify-content-between">
      <h1 style="font-size: 18px">
        {{ project.name_en }}

      </h1>
      <MawjBtn @click="deleteUser" label="Delete" />
    </div>
    <div>
      <span>Project ID: {{ project.project_id }}</span>
    </div>
    <span class="account-active">Project status: {{ project.status_code.name }}</span>
    <div>
      {{ project.rating }}/10
      <b-form-rating id="rating-inline" inline readonly :value="project.rating" stars="10"></b-form-rating>
    </div>
    <div>
      <MawjBtn @click="verifyAccount" :type="project.admin_status == 'Approved' ? 'primary' : ''"
        :label="project.admin_status || 'N/A'" />
    </div>
  </div>
</template>

<script>
import MawjBtn from "@/components/common/mawjBtn.vue";
import { userMethods } from "@/state/helpers";
import { users } from "@/config/api/users";
import { changeStatus } from "@/config/api/projects";
import Swal from "sweetalert2";
import PasswordResetModal from "./passwordResetModal.vue";

export default {
  props: ['project'],
  data() {
    return {
      currentUser: null,
      updatingPassword: false,
      openPasswordModal: false,
    };
  },
  components: {
    MawjBtn,
    PasswordResetModal,
  },
  async created() {
    this.currentUser = await this.getUser();
  },
  methods: {
    ...userMethods,
    verifyEmail() {
      const api = users.update;
      api.id = this.currentUser._id;
      api.data = {
        is_email_verified: !this.currentUser.is_email_verified,
      };

      this.generateAPI(api)
        .then(() => {
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updatePrivateStatus() {
      const api = users.update;
      api.id = this.currentUser._id;
      api.data = {
        is_private: !this.currentUser.is_private,
      };

      this.generateAPI(api)
        .then(() => {
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteUser() {
      Swal.fire({
        title: "Delete user",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          const api = users.delete;
          api.id = this.currentUser._id;
          const res = await this.generateAPI(api);
          console.log(res.data);
          return res;
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "User deleted successfully!",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.user-profile .account-active {
  font-size: 12px;
  color: #000;
}

.user-profile .b-rating {
  border: none;
}
</style>

<style>
.b-rating-star-full {
  color: #f7c97a;
}
</style>
