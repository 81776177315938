<template>
  <Layout bgColor="#fff">
     <div class="text-center" v-if="loading">
      <b-spinner class="m-1" type="grow" label="Spinning"></b-spinner>
      <b-spinner class="m-1" type="grow" label="Spinning"></b-spinner>
      <b-spinner class="m-1" type="grow" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="d-flex justify-content-around flex-wrap" style="background-color: #fff">
        <div>
          <ImageEditor @updated="loadInfo" :url="projectInfo.cover_image" :id="projectInfo._id" />
          <Expert :data="projectInfo" @reload="loadInfo" />
        </div>
        <div style="min-width: 60%;padding:12px">
          <NameDetails :project="projectInfo" @reload="loadInfo" />
          <MainTab @reload="loadInfo" class="mb-5" :data="projectInfo" />
        </div>
        <div>
          <FileAttachment :data="projectInfo" @reload="loadInfo" />
          <SubscriptionPlan :data="{ id: projectInfo._id, plan: projectInfo.plan_level }" class="mt-5" @reload="loadInfo" />
        </div>
      </div>
      <div class="col-12">
        <Projects :data="projectInfo" class="mt-5" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import ImageEditor from "@/components/project-v2/imageEditor.vue";
import NameDetails from "@/components/project-v2/NameDetails.vue";
import FileAttachment from "@/components/project-v2/fileAttachment.vue";
import SubscriptionPlan from "@/components/project-v2/subscriptionPlan.vue";
import Projects from "@/components/project-v2/projects";
import MainTab from "@/components/project-v2/mainTab";
import Expert from "@/components/project-v2/expert";
import { projects } from "@/config/api/projects";
export default {
  components: {
    Layout,
    ImageEditor,
    NameDetails,
    FileAttachment,
    SubscriptionPlan,
    Projects,
    MainTab,
    Expert,
  },
  created() {
    if (this.$route.query.id) {
      this.userId = this.$route.query.id;
      this.loadInfo();
    } else {
      throw "user_id is missing!";
    }
  },
  data() {
    return {
      userInfo: null,
      loading: false,
      userId: null,
      projectInfo: null,
    };
  },

  methods: {
    loadInfo() {
      const api = projects.model.search;
      api.id = this.$route.query.id;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.projectInfo = res.data.project;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
