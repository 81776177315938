<template>
  <div>
    <MawjBtn class="float-right" label="Edit" @click="edit = true" />
    <div class="mb-3">
      <table>
        <tr v-if="data.city">
          <td class="pr-5">Location</td>
          <td>
            {{ data.city.name }} | {{ data.city.country.name }}
          </td>
        </tr>
        <tr>
          <td class="pr-5">Area</td>
          <td>{{ data.location_en }} | {{ data.location_ar }} </td>
        </tr>
        <tr class="pr-5">
          <td>Map</td>
          <td v-if="data.geoloc">
            {{ data.geoloc.latitude }} ,{{ data.geoloc.longitude }}
          </td>
        </tr>
      </table>

      <br>
      <gmap-map v-if="data.geoloc"
        :center="{ lat: data.geoloc.latitude, lng: data.geoloc.longitude }" :zoom="15"
        style="height: 260px; width: 400px;">
        <gmap-marker v-for="(m, index) in [{ lat: data.geoloc.latitude, lng: data.geoloc.longitude }]"
          :key="index" :position="m" :clickable="false" :draggable="false" @click="center = m">
        </gmap-marker>
      </gmap-map>
    </div>

    <!-- <editUser @reload="$emit('reload')" :open="edit" @hide="edit = false" :userData="data" :countries="countries" /> -->
  </div>
</template>

<script>
import MawjBtn from "@/components/common/mawjBtn.vue";
import editUser from "../editUser.vue";
import { countries } from "@/config/api/countries";
export default {
  props: ["data"],
  components: {
    MawjBtn,
    editUser,
  },

  data() {
    return {
      edit: false,
      countries: [],
    };
  },
  methods: {
    loadCountries() {
      const api = countries.get;
      this.generateAPI(api)
        .then((res) => {
          this.countries = res.data.countries.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          });
        })
        .catch((err) => {
          console.error(`Error ${err.response.message}`);
        });
    },
  },
};
</script>
